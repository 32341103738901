import "./Landing.css";
import VideoBg from "../../components/VideoBg/VideoBg";
import Header from "../../components/Header/Header";

function Landing() {
  return (
    <>
      <VideoBg />
    </>
  );
}

export default Landing;
